import React from 'react';
import './dealer-return-modal.scss';

interface DealerReturnOnlyModalProps {
    dealerModalContent: {
        modalHeader: string;
        modalSubCopy: string;
    };
}

const DealerReturnOnlyModalContent = (props: DealerReturnOnlyModalProps) => {
    return (
        <>
            {props.dealerModalContent && (
                <>
                    <h3
                        className="dealer-return-only-modal-header"
                        data-testid="dealer-return-only-modal-header"
                    >
                        {props.dealerModalContent.modalHeader}
                    </h3>
                    <div
                        className="dealer-return-only-modal-sub-copy"
                        data-testid="dealer-return-only-modal-sub-copy"
                        dangerouslySetInnerHTML={{
                            __html: props.dealerModalContent.modalSubCopy,
                        }}
                    />
                </>
            )}
        </>
    );
};

export default DealerReturnOnlyModalContent;
