import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import './guest-login-component.scss';
import { InputField } from '@common/form-fields/form-fields';
import { PrimaryButton } from '@common/index';
import { Link } from 'react-router-dom';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { SearchBar } from '@/components/sections';
import { InputValidationParams } from '@views/vehicle-order-tracking-view/components/vehicle-order-tracking-component';
import { KEYBOARD_KEYS, LinkTargetValues } from '@constants';
import {
    guestLoginComponentErrors,
    GuestLoginComponentErrors,
} from '@views/guest-login-views/components/guest-login-component-errors';
import { GuestLoginContent } from '@views/guest-login-views/guest-login-content-interface';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import { NotificationType } from '@contexts/notificationContext';
import OmsService, {
    OmsOrderDetailsResponse,
} from '@services/oms-service/oms-service';
import HttpService from '@services/http-service/http-service';

interface Props {
    guestLoginContent: GuestLoginContent;
    orderNumber: string;
    setOrderNumber: Dispatch<SetStateAction<string>>;
    emailAddress: string;
    setEmailAddress: Dispatch<SetStateAction<string>>;
    errorNotificationMessage: string;
    setErrorNotificationMessage: Dispatch<SetStateAction<string>>;
    errorNotificationMessageRTE?: string;
    setErrorNotificationMessageRTE?: Dispatch<SetStateAction<string>>;
    prePopulateOrderNumber?: boolean;
    handleOrderDetailsSuccess: (
        orderDetailsData: OmsOrderDetailsResponse
    ) => void;
    rteField?: boolean;
    noNotifTimer?: boolean;
}

const GuestLoginComponent = (props: Props) => {
    const {
        guestLoginContent,
        orderNumber,
        setOrderNumber,
        emailAddress,
        setEmailAddress,
        errorNotificationMessage,
        setErrorNotificationMessage,
        errorNotificationMessageRTE,
        handleOrderDetailsSuccess,
        rteField,
        noNotifTimer,
    } = props;
    const omsService = new OmsService(HttpService);

    const [errors, setErrors] = useState<GuestLoginComponentErrors>(
        guestLoginComponentErrors
    );
    const [orderNumberInputDisabled, setOrderNumberInputDisabled] =
        useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [emailErrorCheck, setEmailErrorCheck] = useState<boolean>(false);
    const [orderNumberErrorCheck, setOrderNumberErrorCheck] =
        useState<boolean>(false);

    const submitButtonIsDisabled: boolean =
        !orderNumber ||
        !emailAddress ||
        errors['orderNumber']?.status ||
        errors['emailAddress']?.status;

    const isValidInputForField = ({
        value,
        field,
        pattern,
        message,
    }: InputValidationParams): boolean => {
        const regex = new RegExp(pattern);

        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };

            if (!regex.test(value)) {
                newErrors[field] = {
                    status: true,
                    message: message,
                };
            } else if (regex.test(value) && prevErrors[field]?.status) {
                newErrors[field] = {
                    status: false,
                    message: '',
                };
            }

            return newErrors;
        });

        return regex.test(value);
    };

    const prePopulateOrderNumber = () => {
        const orderNumberFromStorage = sessionStorage.getItem(
            'nvcOrderDetailsOrderNumber'
        );

        if (orderNumberFromStorage) {
            setOrderNumber(orderNumberFromStorage);
            setOrderNumberInputDisabled(true);
            sessionStorage.removeItem('nvcOrderDetailsOrderNumber');
        }
    };

    const handleSubmit = async () => {
        if (emailAddress && orderNumber) {
            setIsLoading(true);
            const orderDetails = await omsService
                .getOrderDetails(emailAddress.toLocaleLowerCase(), orderNumber)
                .finally(() => {
                    setIsLoading(false);
                });

            if (orderDetails?.orderNo) {
                handleOrderDetailsSuccess(orderDetails);
            } else {
                setErrorNotificationMessage(guestLoginContent.apiErrorMessage);
            }
        }
    };

    const onEnterKeySubmissionErrorCheck = (e) => {
        if (e.key === KEYBOARD_KEYS.ENTER) {
            if (!orderNumber) {
                isValidInputForField({
                    value: '',
                    field: 'orderNumber',
                    pattern:
                        guestLoginContent.orderNumberInputValidationPattern,
                    message:
                        guestLoginContent.orderNumberInputErrorMessageInvalid,
                });
            }
            if (!emailAddress) {
                isValidInputForField({
                    value: '',
                    field: 'emailAddress',
                    pattern:
                        guestLoginContent.emailAddressInputValidationPattern,
                    message:
                        guestLoginContent.emailAddressInputErrorMessageInvalid,
                });
            }
            setOrderNumberErrorCheck(true);
            setEmailErrorCheck(true);
        }
    };

    useEffect(() => {
        if (props.prePopulateOrderNumber) prePopulateOrderNumber();
    }, []);

    return (
        <>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            {guestLoginContent ? (
                <div
                    className="guest-login-component__container"
                    data-testid="guest-login-component__container"
                >
                    <div className="guest-login-component__content-container">
                        {errorNotificationMessage && (
                            <div
                                className={`guest-login-component__notification-container`}
                                data-testid={`guest-login-component__notification-container`}
                            >
                                <Notification
                                    status={NotificationType.Error}
                                    mainCopy={errorNotificationMessage}
                                    subCopy={errorNotificationMessageRTE}
                                    rteField={rteField}
                                    hideBorder={true}
                                    hideAfterTimeout={!noNotifTimer}
                                    onHideNotification={() => {
                                        setErrorNotificationMessage(null);
                                    }}
                                />
                            </div>
                        )}

                        <h1 className="header-text" data-testid="header-text">
                            {guestLoginContent.headerText}
                        </h1>

                        <p
                            className="subheader-text"
                            data-testid="subheader-text"
                        >
                            {guestLoginContent.subheaderText}
                        </p>

                        <form
                            action=""
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit;
                            }}
                            onKeyDown={(e) => {
                                onEnterKeySubmissionErrorCheck(e);
                            }}
                        >
                            <div className="input-container">
                                <InputField
                                    id="order-number"
                                    dataTestId="order-number-input"
                                    value={orderNumber}
                                    handleChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setOrderNumber(e.target.value);
                                        setOrderNumberErrorCheck(false);

                                        isValidInputForField({
                                            value: e.target.value,
                                            field: 'orderNumber',
                                            pattern:
                                                guestLoginContent.orderNumberInputValidationPattern,
                                            message:
                                                guestLoginContent.orderNumberInputErrorMessageInvalid,
                                        });
                                    }}
                                    name="orderNumber"
                                    label={
                                        guestLoginContent.orderNumberInputLabelText
                                    }
                                    aria-label={
                                        guestLoginContent.orderNumberInputAriaLabel
                                    }
                                    onBlur={() => {
                                        setOrderNumberErrorCheck(true);
                                    }}
                                    error={
                                        orderNumberErrorCheck
                                            ? errors?.orderNumber
                                            : null
                                    }
                                    showLabel={false}
                                    disabled={orderNumberInputDisabled}
                                />
                                <InputField
                                    id="email-address"
                                    dataTestId="email-address-input"
                                    value={emailAddress}
                                    handleChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setEmailAddress(e.target.value);

                                        setEmailErrorCheck(false);

                                        isValidInputForField({
                                            value: e.target.value,
                                            field: 'emailAddress',
                                            pattern:
                                                guestLoginContent.emailAddressInputValidationPattern,
                                            message:
                                                guestLoginContent.emailAddressInputErrorMessageInvalid,
                                        });
                                    }}
                                    name="emailAddress"
                                    label={
                                        guestLoginContent.emailAddressInputLabelText
                                    }
                                    aria-label={
                                        guestLoginContent.emailAddressInputAriaLabel
                                    }
                                    validationPattern={
                                        guestLoginContent.emailAddressInputValidationPattern
                                    }
                                    validationRules={[
                                        'validateNonEmpty',
                                        'validateMatchesPattern',
                                    ]}
                                    onBlur={() => {
                                        setEmailErrorCheck(true);
                                    }}
                                    error={
                                        emailErrorCheck
                                            ? errors?.emailAddress
                                            : null
                                    }
                                    showLabel={false}
                                />
                            </div>

                            <PrimaryButton
                                id="submit-details-button"
                                tabIndex={0}
                                testId="submit-details-button"
                                ariaLabel={
                                    guestLoginContent.submitDetailsButtonAriaLabel
                                }
                                onClick={handleSubmit}
                                disabled={submitButtonIsDisabled}
                            >
                                {guestLoginContent.submitDetailsButtonLabelText}
                            </PrimaryButton>
                        </form>

                        <div
                            className="help-text"
                            data-testid="help-text-section"
                        >
                            <p className="help-text__prefix">
                                {guestLoginContent.cantFindOrderNumberText}
                            </p>
                            <Link
                                to={
                                    guestLoginContent.contactCustomerServiceLinkHref
                                }
                                aria-label={
                                    guestLoginContent.contactCustomerServiceLinkAriaLabel
                                }
                                className="contact-customer-service-link"
                                target={LinkTargetValues.BLANK}
                            >
                                {
                                    guestLoginContent.contactCustomerServiceLinkText
                                }
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <ActivityIndicator />
            )}
            <hr className="guest-login-component__divider" />
            <SearchBar />
        </>
    );
};

export default GuestLoginComponent;
