import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HttpService from '@/services/http-service/http-service';
import { findPathByAlias } from '@/routes/routesList';
import { useWindowSize } from '@hooks/use-window-size';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import BingService, {
    BingDealerInfo,
} from '@/services/bing-service/bing-service';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';
import { useOrderDetailsContent } from '../order-details-view/hooks/use-order-details-content';
import './return-to-dealer-view.scss';

const className = 'return-to-dealer';

const ReturnToDealer: React.FC = () => {
    const httpService = HttpService;
    const bingService = new BingService(httpService);
    const appConfig = new AppConfigurationService();
    const location = useLocation();
    const orderDetailsContent = useOrderDetailsContent();
    const size = useWindowSize();
    const [isMobile, setIsMobile] = useState(false);
    const [dealerImgSrc, setDealerImageSrc] = useState<string>();
    const [dealerInfo, setDealerInfo] = useState<BingDealerInfo>();
    const [mapLoaded, setMapLoaded] = useState(false);
    const dealerId = location.state?.dealerId;
    let width = 542;
    const height = 200;

    const bingKey =
        'Al1EdZ_aW5T6XNlr-BJxCw1l4KaA0tmXFI_eTl1RITyYptWUS0qit_MprtcG7w2F';

    const getDealerStaticMapData = async () => {
        const dealerInfo = await Promise.resolve(getDealerData());
        setDealerInfo(dealerInfo);
        getDealerStaticImg(dealerInfo);
    };

    const getDealerData = async () => {
        const response = await bingService.getDealerInfoById(dealerId);
        if (response?.length) {
            return response[0];
        }
    };

    const getDealerStaticImg = (dealerInfo) => {
        if (isMobile) width = 356;
        console.log('--ismobile--', isMobile, width);
        const staticImgUrl = `https://dev.virtualearth.net/REST/v1/Imagery/Map/Road/${dealerInfo.latitude},${dealerInfo.longitude}/12?mapSize=${width},${height}&pp=${dealerInfo.latitude},${dealerInfo.longitude};66&mapLayer=Basemap,Buildings&key=${bingKey}`;
        setDealerImageSrc(staticImgUrl);
    };

    useEffect(() => {
        getDealerStaticMapData().then(() => setMapLoaded(true));
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);

    useEffect(() => {
        if (size.width < 992 && !isMobile) {
            setIsMobile(true);
        } else if (size.width >= 992 && isMobile) {
            setIsMobile(false);
        }
    }, [size.width]);

    useEffect(() => {
        if (dealerInfo) {
            getDealerStaticImg(dealerInfo);
        }
    }, [isMobile]);

    return (
        <>
            {orderDetailsContent && mapLoaded && (
                <div
                    className={`${className}-container`}
                    data-test-id={`${className}-view`}
                >
                    <Breadcrumbs
                        currentPage={
                            orderDetailsContent.createReturnBreadcrumbTitle
                        }
                        previousPage={
                            orderDetailsContent.createReturnBreadcrumbPreviousTitle
                        }
                        secondPreviousPage={
                            orderDetailsContent.createReturnBreadcrumbSecondPreviousTitle
                        }
                        previousLink={
                            location.state?.orderDetails
                                ? findPathByAlias(
                                      'OrderDetailsViewWithoutOrderNumber',
                                      location.state?.orderDetails.orderNo
                                  )
                                : findPathByAlias('OrderHistoryView')
                        }
                        secondPreviousLink={findPathByAlias('OrderHistoryView')}
                        crumbSeparator={appConfig.brand === 'ford' ? '/' : '>'}
                    ></Breadcrumbs>
                    <div
                        className={`${className}-component-container`}
                        data-test-id={`${className}-component-container`}
                    >
                        <h1 className={`${className}-component-title`}>
                            {orderDetailsContent?.returnToDealerPageHeaderText ||
                                'Return to Dealer'}
                        </h1>
                        <div
                            className={`${className}-component-subtitle`}
                            data-testid={`${className}-component-subtitle`}
                            dangerouslySetInnerHTML={{
                                __html: orderDetailsContent.returnToDealerPageDescriptionText,
                            }}
                        ></div>
                        <div
                            className={`${className}-component-items`}
                            data-testid={`${className}-component-items`}
                        >
                            <div className={`${className}-component-map`}>
                                <img
                                    src={dealerImgSrc}
                                    alt="dealer location"
                                    data-testid={`${className}-component-map`}
                                />
                            </div>
                            <div className={`${className}-component-address`}>
                                <p
                                    className={'dealer-name'}
                                    data-testid={`${className}-name`}
                                >
                                    {dealerInfo.dealerName}
                                </p>
                                <hr className="hr-line" aria-hidden={true} />
                                <p data-testid={`${className}-address`}>
                                    {dealerInfo.streetAddress +
                                        `, ` +
                                        dealerInfo.city +
                                        `, ` +
                                        dealerInfo.state +
                                        `, ` +
                                        dealerInfo.zip}
                                </p>
                                <p>{dealerInfo.phone}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ReturnToDealer;
