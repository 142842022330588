import { OmsOrderDetailsResponse } from '@services/oms-service/oms-service';
import { findPathByAlias } from '@routes/routesList';
import { NavigateFunction } from 'react-router-dom';

export const hasReturnableShippingItems = (
    orderDetails: OmsOrderDetailsResponse
): boolean => {
    return orderDetails.shippingOrderLine?.some(
        (order) => order.isStorefrontReturnable === 'Y'
    );
};

export const hasReturnablePickupItems = (
    orderDetails: OmsOrderDetailsResponse
): boolean => {
    return orderDetails.pickUpOrderLine?.some(
        (order) =>
            order.isStorefrontReturnable === 'Y' ||
            (order.isStorefrontReturnable === 'N' &&
                order.returnableAtDealership === 'Y')
    );
};

export const goToShippingLineReturn = (
    orderDetails: OmsOrderDetailsResponse,
    navigate: NavigateFunction,
    guestOrder: boolean
): void => {
    navigate(findPathByAlias('CreateReturnView'), {
        state: {
            orderDetails: {
                ...orderDetails,
                orderNo: orderDetails?.shippingOrderLine[0]?.orderNo,
            },
            guestOrder,
            orderLineCategory: 'shippingOrderLine',
        },
    });
};

export const goToPickupLineReturn = (
    orderDetails: OmsOrderDetailsResponse,
    navigate: NavigateFunction,
    guestOrder: boolean
): void => {
    navigate(findPathByAlias('CreateReturnView'), {
        state: {
            orderDetails: {
                ...orderDetails,
                orderNo: orderDetails?.pickUpOrderLine[0]?.orderNo,
            },
            guestOrder,
            orderLineCategory: 'pickUpOrderLine',
        },
    });
};

export const goToOrderDetails = (
    orderDetails: OmsOrderDetailsResponse,
    navigate: NavigateFunction,
    orderNumber: string,
    guestOrder: boolean,
    location: any
): void => {
    navigate(
        {
            pathname: findPathByAlias(
                'OrderDetailsViewWithoutOrderNumber',
                orderNumber
            ),
            search: location.search,
        },
        {
            state: {
                orderDetails,
                guestOrder,
            },
        }
    );
};
