import React, { useEffect, useState } from 'react';
import SecondaryButton from '@common/secondary-button/secondary-button';
import {
    OmsOrderDetailsResponse,
    OrderLineDetails,
} from '@/services/oms-service/oms-service';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { OrderDetailsContent } from '../hooks/use-order-details-content';
import { ProfileWithVehiclesResponse } from '@/models/profile-with-vehicles';
import OrderTotals from '@views/order-details-view/components/order-totals/order-totals';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import {
    NVC_ORDER_STATUS_MAP,
    OrderStatusOption,
    transformDeliveryMethod,
} from '@views/order-details-view/nvc-status-map';
import { useLocation, useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@/routes/routesList';
import { useAnalytics } from '@/hooks/use-analytics';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { LinkTargetValues } from '@/constants';
import SubOrder from '@views/order-details-view/components/sub-order/sub-order';
import OrderDetailsAddress from '@views/order-details-view/components/order-details-address/order-details-address';

const className = 'order-details-component';

interface Props {
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
    orderDetailsData: OmsOrderDetailsResponse;
    ordersFinishedLoading: boolean;
    authenticated: ProfileWithVehiclesResponse;
}

const OrderDetailsComponent = (props: Props) => {
    const {
        orderDetailsContent,
        orderDetailsData,
        ordersFinishedLoading,
        orderStatusContent,
    } = props;
    const [fireAnalytics] = useAnalytics();
    const location = useLocation();
    const date = new Date(orderDetailsData?.orderDate);
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const [shippingLineItems, setShippingLineItems] =
        useState<OrderLineDetails[]>(null);
    const [pickupLineItems, setPickupLineItems] =
        useState<OrderLineDetails[]>(null);
    const languageRegionCode = appConfig.getLanguageRegionCode().toLowerCase();
    const formattedDate = date.toLocaleDateString(languageRegionCode, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const showShippingStartReturnButton =
        orderDetailsData?.shippingOrderLine?.some(
            (item) => item.isStorefrontReturnable === 'Y'
        );
    const showPickupStartReturnButton = orderDetailsData?.pickUpOrderLine?.some(
        (item) =>
            item.isStorefrontReturnable === 'Y' ||
            (item.isStorefrontReturnable === 'N' &&
                item.returnableAtDealership === 'Y')
    );
    const navigate = useNavigate();

    const stringifiedOrderData = orderDetailsData;

    const returnPolicyPageUrl = orderDetailsContent.returnPolicyPageUrl;

    const sortItems = (deliveryCategory: string): OrderLineDetails[] => {
        let finalItems: OrderLineDetails[] = [];
        const deliveryMethod: string =
            deliveryCategory === 'shippingOrderLine' ? 'SHP' : 'PICK';
        const deliveryMethodTransformed =
            transformDeliveryMethod(deliveryMethod);

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].forEach(
            (statusOption: OrderStatusOption) => {
                const filteredList = orderDetailsData[deliveryCategory].filter(
                    (item) =>
                        statusOption.options.includes(item.status.toLowerCase())
                );

                if (filteredList.length) {
                    finalItems = [...finalItems, ...filteredList];
                }
            }
        );

        return finalItems;
    };

    useEffect(() => {
        if (orderDetailsData) {
            if (orderDetailsData.shippingOrderLine?.length) {
                setShippingLineItems(sortItems('shippingOrderLine'));
            }
            if (orderDetailsData.pickUpOrderLine?.length) {
                setPickupLineItems(sortItems('pickUpOrderLine'));
            }
        }
    }, [orderDetailsData]);

    return (
        <>
            {ordersFinishedLoading && orderDetailsContent.billingAddress && (
                <div
                    className={`${className}-container`}
                    data-test-id={`${className}`}
                >
                    <h1
                        className={
                            props.authenticated
                                ? `${className}-title`
                                : `${className}-title-unauthenticated`
                        }
                        data-testid={`${className}-title`}
                    >
                        {orderDetailsContent.order}{' '}
                        {orderDetailsData?.orderNo ||
                            '#' + location.pathname.split('FAE:')[1]}
                    </h1>

                    {orderDetailsData && (
                        <>
                            <div className={`${className}-details`}>
                                <p className={`${className}-order-date`}>
                                    {orderDetailsContent.orderDate}
                                </p>
                                <p className={`${className}-date`}>
                                    {formattedDate}
                                </p>

                                {orderDetailsData.personInfoBillTo ||
                                orderDetailsData.personInfoShipTo ? (
                                    <OrderDetailsAddress
                                        orderDetailsContent={
                                            orderDetailsContent
                                        }
                                        orderDetailsData={orderDetailsData}
                                        className={className}
                                    />
                                ) : null}

                                {(showShippingStartReturnButton ||
                                    showPickupStartReturnButton) && (
                                    <div
                                        className={`${className}-return-policy-container`}
                                    >
                                        <SecondaryButton
                                            aria-label={
                                                orderDetailsContent.returnPolicyButtonAriaLabel
                                            }
                                            internal={false}
                                            link={returnPolicyPageUrl}
                                            linkTarget={LinkTargetValues.BLANK}
                                            onClick={() => {
                                                fireAnalytics(
                                                    'orderDetailsReturnsPolicyOnclickEvent'
                                                );
                                            }}
                                        >
                                            {orderDetailsContent?.returnPolicy}
                                        </SecondaryButton>
                                    </div>
                                )}
                            </div>

                            {shippingLineItems?.length && (
                                <>
                                    <div className="sub-order__heading">
                                        <p className="sub-order__heading--number">
                                            {shippingLineItems[0].orderNo}
                                        </p>
                                        {showShippingStartReturnButton && (
                                            <SecondaryButton
                                                dataTestId="start-return-button"
                                                aria-label={
                                                    orderDetailsContent.startReturn
                                                }
                                                onClick={() => {
                                                    navigate(
                                                        findPathByAlias(
                                                            'CreateReturnView'
                                                        ),
                                                        {
                                                            state: {
                                                                orderDetails: {
                                                                    ...stringifiedOrderData,
                                                                    orderNo:
                                                                        shippingLineItems[0]
                                                                            .orderNo,
                                                                },
                                                                orderLineCategory:
                                                                    'shippingOrderLine',
                                                                guestOrder:
                                                                    location
                                                                        .state
                                                                        ?.guestOrder,
                                                            },
                                                        }
                                                    );
                                                }}
                                            >
                                                {
                                                    orderDetailsContent.startReturn
                                                }
                                            </SecondaryButton>
                                        )}
                                    </div>
                                    <hr
                                        className="hr-line"
                                        aria-hidden={true}
                                    />
                                    <SubOrder
                                        orderStatusContent={orderStatusContent}
                                        orderDetailsContent={
                                            orderDetailsContent
                                        }
                                        orderDetailsItems={shippingLineItems}
                                    />
                                </>
                            )}
                            {pickupLineItems?.length && (
                                <>
                                    <div className="sub-order__heading">
                                        <p className="sub-order__heading--number">
                                            {pickupLineItems[0].orderNo}
                                        </p>
                                        {showPickupStartReturnButton && (
                                            <SecondaryButton
                                                dataTestId="start-return-button"
                                                aria-label={
                                                    orderDetailsContent.startReturn
                                                }
                                                onClick={() => {
                                                    navigate(
                                                        findPathByAlias(
                                                            'CreateReturnView'
                                                        ),
                                                        {
                                                            state: {
                                                                orderDetails: {
                                                                    ...stringifiedOrderData,
                                                                    orderNo:
                                                                        pickupLineItems[0]
                                                                            .orderNo,
                                                                },
                                                                orderLineCategory:
                                                                    'pickUpOrderLine',
                                                                guestOrder:
                                                                    location
                                                                        .state
                                                                        ?.guestOrder,
                                                            },
                                                        }
                                                    );
                                                }}
                                            >
                                                {
                                                    orderDetailsContent.startReturn
                                                }
                                            </SecondaryButton>
                                        )}
                                    </div>
                                    <hr
                                        className="hr-line"
                                        aria-hidden={true}
                                    />
                                    <SubOrder
                                        orderStatusContent={orderStatusContent}
                                        orderDetailsContent={
                                            orderDetailsContent
                                        }
                                        orderDetailsItems={pickupLineItems}
                                    />
                                </>
                            )}

                            <OrderTotals
                                orderDetailsContent={orderDetailsContent}
                                orderDetailsData={orderDetailsData}
                            />
                        </>
                    )}
                </div>
            )}
            {!ordersFinishedLoading ? (
                <ActivityIndicator className={'full-height'} />
            ) : null}
        </>
    );
};

export default OrderDetailsComponent;
