import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGuestOrderLoginContent } from '@views/guest-login-views/guest-order-view/hooks/use-guest-order-login-content';
import GuestLoginComponent from '@views/guest-login-views/components/guest-login-component';
import { OmsOrderDetailsResponse } from '@services/oms-service/oms-service';
import { findPathByAlias } from '@routes/routesList';

const GuestOrderView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const guestOrderLoginContent = useGuestOrderLoginContent();

    const [emailAddress, setEmailAddress] = useState<string>('');
    const [orderNumber, setOrderNumber] = useState<string>('');
    const guestOrder = true;
    const [errorNotificationMessage, setErrorNotificationMessage] =
        useState<string>(null);

    const handleOrderDetailsSuccess = (
        orderDetails: OmsOrderDetailsResponse
    ) => {
        navigate(
            {
                pathname: findPathByAlias(
                    'OrderDetailsViewWithoutOrderNumber',
                    orderNumber
                ),
                search: location.search,
            },
            {
                state: {
                    orderDetails,
                    guestOrder,
                },
            }
        );
    };

    return (
        <GuestLoginComponent
            guestLoginContent={guestOrderLoginContent}
            emailAddress={emailAddress}
            setEmailAddress={setEmailAddress}
            orderNumber={orderNumber}
            setOrderNumber={setOrderNumber}
            errorNotificationMessage={errorNotificationMessage}
            setErrorNotificationMessage={setErrorNotificationMessage}
            prePopulateOrderNumber={true}
            handleOrderDetailsSuccess={handleOrderDetailsSuccess}
        />
    );
};

export default GuestOrderView;
