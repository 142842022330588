import React, { useState } from 'react';
import { useGuestReturnLoginContent } from '@views/guest-login-views/guest-return-view/hooks/use-guest-return-login-content';
import GuestLoginComponent from '@views/guest-login-views/components/guest-login-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { OmsOrderDetailsResponse } from '@services/oms-service/oms-service';
import {
    goToOrderDetails,
    goToPickupLineReturn,
    goToShippingLineReturn,
    hasReturnablePickupItems,
    hasReturnableShippingItems,
} from '@views/guest-login-views/return-utils';

const GuestReturnView = () => {
    const guestReturnContent = useGuestReturnLoginContent();
    const navigate = useNavigate();
    const location = useLocation();
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [orderNumber, setOrderNumber] = useState<string>('');
    const guestReturn = true;
    const [errorNotificationMessage, setErrorNotificationMessage] =
        useState<string>(null);
    const [errorNotificationMessageRTE, setErrorNotificationMessageRTE] =
        useState<string>(null);

    const handleOrderDetailsSuccess = (
        orderDetails: OmsOrderDetailsResponse
    ) => {
        const hasOnlyReturnablePickupItems =
            hasReturnablePickupItems(orderDetails) &&
            !hasReturnableShippingItems(orderDetails);
        const hasOnlyReturnableShippingItems =
            !hasReturnablePickupItems(orderDetails) &&
            hasReturnableShippingItems(orderDetails);

        if (orderNumber == orderDetails.orderNo) {
            if (hasOnlyReturnablePickupItems) {
                goToPickupLineReturn(orderDetails, navigate, guestReturn);
            } else if (hasOnlyReturnableShippingItems) {
                goToShippingLineReturn(orderDetails, navigate, guestReturn);
            } else {
                goToOrderDetails(
                    orderDetails,
                    navigate,
                    orderNumber,
                    guestReturn,
                    location
                );
            }
        }

        if (orderNumber == orderDetails?.shippingOrderLine[0]?.orderNo) {
            goToShippingLineReturn(orderDetails, navigate, guestReturn);
        } else if (orderNumber == orderDetails?.pickUpOrderLine[0]?.orderNo) {
            goToPickupLineReturn(orderDetails, navigate, guestReturn);
        } else {
            setErrorNotificationMessage(
                guestReturnContent.noReturnableItemsErrorMessage
            );
            setErrorNotificationMessageRTE(
                guestReturnContent.noReturnableItemsErrorMessageRTEContent
            );
        }
    };

    return (
        <>
            <GuestLoginComponent
                guestLoginContent={guestReturnContent}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                orderNumber={orderNumber}
                setOrderNumber={setOrderNumber}
                errorNotificationMessage={errorNotificationMessage}
                setErrorNotificationMessage={setErrorNotificationMessage}
                errorNotificationMessageRTE={errorNotificationMessageRTE}
                setErrorNotificationMessageRTE={setErrorNotificationMessageRTE}
                handleOrderDetailsSuccess={handleOrderDetailsSuccess}
                rteField={true}
                noNotifTimer={true}
            />
        </>
    );
};

export default GuestReturnView;
